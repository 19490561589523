import { likeRegExp } from '@/offline/database'

export default {
  _selectSubsisBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.subsis)
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .orderBy(tables.subsis.orden)
  },
  async selectSubsis (Vue, filter, search, sorter, page, idsistema) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.subsis.codigo.match(reQ),
          tables.subsis.descripcion.match(reQ),
          tables.tsubsis.descripcion.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.subsis.idsistema.eq(idsistema),
        tables.subsis.estado.gt(0),
        ...whereSearch,
        ...whereFilter
      )
    )
    return [
      await this._selectSubsisBase(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .exec(),
      []
    ]
  },
  async selectSubsisRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return await this._selectSubsisBase(Vue)
      .where(tables.subsis.idsubsis.in(pks))
      .exec()
  }
}
